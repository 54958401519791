<template>
    <div>

        <!-- Banner -->
        <app-banner page-name="Check Out" />
        <!-- End Banner -->

        <!-- Page Content -->
        <div id="main_area">
            <div class="wrapper">
                <main>
                    <div class="breadcrumbs">
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Home' }" tag="a" property="item" typeof="WebPage" title="Go to Home." class="home">
                                <span property="name">Home</span>
                            </router-link>
                            <meta property="position" content="1">
                        </span> &gt;
                        <span property="itemListElement" typeof="ListItem">
                            <router-link :to="{ name: 'Store' }" tag="a" property="item" typeof="WebPage" title="Go to Store." class="post post-page">
                                <span property="name">Store</span>
                            </router-link>
                            <meta property="position" content="2">
                        </span> &gt;
                        <span class="post post-page current-item">Shopping Cart</span>
                    </div>
                    <div id="post-10" class="post-10 page type-page status-publish hentry">
                        <div class="entry-content">
                            <main>

                                <div class="checkout-page">
                                    <section class="">
                                        <ul class="products">
                                            <li class="row" v-for="(item, index) in items" :key="`product-${index}`">
                                                <div class="col left">
                                                    <div class="thumbnail">
                                                        <img :src="`${apiDomain}${item.image}`" :alt="item.name" />
                                                    </div>
                                                    <div class="detail">
                                                        <div v-if="item.name" class="name">{{ item.name }}</div>
                                                        <div v-if="item.description" class="description">{{ item.description }}</div>

                                                        <template v-if="item.hasOptions">
                                                            <div class="price">${{ item.options.find(product => product.selected).price }}</div>
                                                            <div class="price size">Size: {{ item.options.find(product => product.selected).size }}</div>
                                                        </template>
                                                        <div v-else class="price">${{ item.price }}</div>
                                                    </div>
                                                </div>

                                                <div class="col right">
                                                    <div class="quantity">
                                                        <p class="m-0 p-0">Quantity</p>
                                                        <input
                                                            type="text"
                                                            class="quantity"
                                                            step="1"
                                                            readonly
                                                            v-model="item.qty"
                                                        />
                                                    </div>

                                                    <div class="quantity">
                                                        <p class="m-0 p-0">Subtotal</p>
                                                        <input
                                                            type="text"
                                                            class="quantity"
                                                            step="1"
                                                            readonly
                                                            style="border: 0!important; background: transparent!important;"
                                                            :value="`$${getTotalProductPrice(item)}`"
                                                        />
                                                        <!-- <svg
                                                            version="1.1"
                                                            class="close"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 60 60"
                                                            enableBackground="new 0 0 60 60"
                                                        >
                                                            <polygon points="38.936,23.561 36.814,21.439 30.562,27.691 24.311,21.439 22.189,23.561 28.441,29.812 22.189,36.064 24.311,38.186 30.562,31.934 36.814,38.186 38.936,36.064 32.684,29.812" />
                                                        </svg> -->
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </section>

                                    <section class="">
                                        <ul class="products">
                                            <li class="row" style="min-height: 70px;">
                                                <div class="col right" style="top: 0!important;">
                                                    <div class="quantity">
                                                        <input
                                                            type="text"
                                                            class="quantity"
                                                            readonly
                                                            value="Total"
                                                            style="border: 0!important; background: transparent!important;"
                                                        />
                                                    </div>

                                                    <div class="quantity">
                                                        <input
                                                            type="text"
                                                            class="quantity"
                                                            step="1"
                                                            readonly
                                                            style="border: 0!important; background: transparent!important;"
                                                            :value="`$${getInvoiceTotalPrice()}`"
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </section>
                                </div>

                                <div id="mc_embed_signup">
                                    <checkout-form :total="getInvoiceTotalPrice()" />
                                </div>

                            </main>
                        </div>
                    </div>
                </main>
                <div class="clearfix"></div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import banner from '@/components/layouts/banner'
import CheckoutForm from '@/components/forms/CheckoutForm'

export default {
    name: 'CheckOut',
    components: {
        CheckoutForm,
        appBanner: banner,
    },
    computed: {
        ...mapGetters('cart', ['items']),
    },
    methods: {
        getTotalProductPrice(item) {
            if (item.hasOptions) {
                const product = item.options.find(product => product.selected)

                return Number(product.qty || 1) * Number(product.price)
            } else {
                return Number(item.qty || 1) * Number(item.price)
            }
        },
        getInvoiceTotalPrice() {

            // Use meaningful variable names for better readability
            const total = this.items.reduce((acc, item) => {
                if (item.hasOptions) {
                    // Use Array.find to get the selected product with options
                    const selectedProduct = item.options.find(product => product.selected) || {};
                    // Use destructuring to get the relevant properties with default values
                    const { qty = 0, price = 0 } = selectedProduct;
                    // Update the accumulator with the calculated price
                    acc.price += qty * Number(price);
                } else {
                    // Update the accumulator with the calculated price for items without options
                    acc.price += item.qty * Number(item.price);
                }

                return acc;
            }, { price: 0 }); // Start with an object for better extensibility

            // Add a comment to clarify the purpose of the function
            return total.price;
        }
    },
}
</script>

<style lang="scss">
.checkout-page {
    .p-0 {
        padding: 0!important;
    }

    .m-0 {
        margin: 0!important;
    }

    img {
        max-width: 100%;
        border-radius: 4px;
        vertical-align: middle;
        width: 100px!important;
    }

    a {
        text-decoration: none;
        color: #333333;
    }

    a:hover {
        color: #f58551;
    }

    button {
        background-color: #16cc9b;
        border: 2px solid #16cc9b;
        color: #ffffff;
        transition: all 0.25s linear;
        cursor: pointer;
    }

    button::after {
        position: relative;
        right: 0;
        content: " \276f";
        transition: all 0.15s linear;
    }

    button:hover {
        background-color: #f58551;
        border-color: #f58551;
    }

    button:hover::after {
        right: -5px;
    }

    button:focus {
        outline: none;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    input {
        transition: all 0.25s linear;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    input {
        outline: none;
    }

    .container {
        width: 90%;
        margin: 0 auto;
        overflow: auto;
    }

    /* --- HEADER --- */
    header.container {
        margin-bottom: 1.5rem;
    }

    header .breadcrumb {
        color: #7d7d7d;
    }

    header .breadcrumb li {
        float: left;
        padding: 0 6px;
    }

    header .breadcrumb li:first-child {
        padding-left: 2px;
    }

    header .breadcrumb li:not(:last-child)::after {
        content: " \276f";
        padding-left: 8px;
    }

    header .count {
        float: right;
        color: #333333;
    }

    /* --- PRODUCT LIST --- */
    .products {
        border-top: 1px solid #ddd;
    }

    .products > li {
        padding: 1rem 0;
        border-bottom: 1px solid #ddd;
    }

    .row {
        position: relative;
        overflow: auto;
        width: 100%;
    }

    .col, .quantity, .remove {
        float: left;
    }

    .col.left {
        width: 70%;
    }

    .col.right {
        width: 30%;
        position: absolute;
        right: 0;
        top: calc(50% - 50px);
    }

    .detail {
        padding: 0 0.5rem;
        line-height: 2.2rem;
    }

    .detail .name {
        font-size: 1.2rem;
    }

    .detail .description {
        color: #7d7d7d;
        font-size: 1rem;
    }

    .detail .price {
        font-size: 1.5rem;
    }

    .quantity, .remove {
        width: 50%;
        text-align: center;
    }

    .remove svg {
        width: 60px;
        height: 60px;
    }

    .quantity > input {
        display: inline-block;
        width: 60px;
        height: 60px;
        position: relative;
        left: calc(50% - 30px);
        background: #fff;
        border: 2px solid #ddd;
        color: #7f7f7f;
        text-align: center;
        font: 600 1.5rem Helvetica, Arial, sans-serif;
    }

    .quantity > input:hover, .quantity > input:focus {
        border-color: #f58551;
    }

    .close {
        fill: #7d7d7d;
        transition: color 150ms linear,
            background-color 150ms linear,
            fill 150ms linear,
            150ms opacity linear;
        cursor: pointer;
    }

    .close:hover {
        fill: #f58551;
    }

    /* --- SUMMARY --- */
    .promotion, .summary, .checkout {
        float: left;
        width: 100%;
        margin: 1.5rem 0;
    }

    .promotion > label {
        float: left;
        width: 100%;
        margin-bottom: 1rem;
    }

    .promotion > input {
        float: left;
        width: 80%;
        font-size: 1rem;
        padding: 0.5rem 0 0.5rem 1.8rem;
        border: 2px solid #16cc9b;
        border-radius: 2rem 0 0 2rem;
    }

    .promotion:hover > input {
        border-color: #f58551;
    }

    .promotion > button {
        float: left;
        width: 20%;
        padding: 0.5rem 0;
        border-radius: 0 2rem 2rem 0;
    }

    .promotion:hover > button {
        border-color: #f58551;
        background-color: #f58551;
    }

    .promotion > button::after {
        content: "\276f";
        font-size: 1rem;
    }

    .summary {
        font-size: 1.2rem;
        text-align: right;
    }

    .summary ul li {
        padding: 0.5rem 0;
    }

    .summary ul li span {
        display: inline-block;
        width: 30%;
    }

    .summary ul li.total {
        font-weight: bold;
    }

    .checkout {
        text-align: right;
    }

    .checkout > button {
        font-size: 1.2rem;
        padding: 0.8rem 2.8rem;
        border-radius: 1.5rem;
    }

    .empty-product {
        text-align: center;
    }

    .empty-product > button {
        font-size: 1.3rem;
        padding: 10px 30px;
        border-radius: 5px;
    }

    /* --- SMALL SCREEN --- */
    @media all and (max-width: 599px) {
        .thumbnail img {
            display: none;
        }

        .quantity > input {
            width: 40px;
            height: 40px;
            left: calc(50% - 20px);
        }

        .remove svg {
            width: 40px;
            height: 40px;
        }
    }

    /* --- MEDIUM & LARGE SCREEN --- */
    @media all and (min-width: 600px) {
        html {
            font-size: 14px;
        }

        .container {
            width: 75%;
            max-width: 960px;
        }

        .thumbnail, .detail {
            float: left;
        }

        .thumbnail {
            width: 35%;
        }

        .detail {
            width: 65%;
        }

        .promotion, .summary {
            width: 50%;
        }

        .checkout {
            width: 100%;
        }

        .checkout, .summary {
            text-align: right;
        }
    }

    /* --- LARGE SCREEN --- */
    @media all and (min-width: 992px) {
        html {
            font-size: 16px;
        }
    }
}
</style>
