<template>
    <div class="clearfix">

        <alert :alertData="alertData"/>

        <div class="wrapper">
            <div id="contact_us_form_1" class="template_form">
                <div class="form_frame_b">
                    <div class="form_content">
                        <form id="submitform" name="contact" @submit.prevent="handleSubmitForm">
                            <span class="required-info">* Required Information</span>
                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Full Name
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="crm.clientName" :class="{ 'error': submitted && $v.crm.clientName.$error }" name="Name" class="form_field" id="Name" placeholder="Enter name here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Phone no.
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="crm.phoneNumber" :class="{ 'error': submitted && $v.crm.phoneNumber.$error }" name="Phone_Day" class="form_field" id="Phone_Day" placeholder="Enter phone day here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Email Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="crm.email" :class="{ 'error': submitted && $v.crm.email.$error }" name="Email_Address" class="form_field" id="Email_Address" placeholder="Enter email address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Shipping Address
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="crm.address" :class="{ 'error': submitted && $v.crm.address.$error }" name="Address" class="form_field" id="Address" placeholder="Enter address here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>City
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="crm.city" :class="{ 'error': submitted && $v.crm.city.$error }" name="City" class="form_field" id="City" placeholder="Enter city here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form_box">
                                <div class="form_box_col2">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>State
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="select-field">
                                            <select v-model="crm.state" :class="{ 'error': submitted && $v.crm.state.$error }" name="State" class="form_field">
                                                <option value="">Please select state.</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="District Of Columbia">District Of Columbia</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="New Hampshire">New Hampshire</option>
                                                <option value="New Jersey">New Jersey</option>
                                                <option value="New Mexico">New Mexico</option>
                                                <option value="New York">New York</option>
                                                <option value="North Carolina">North Carolina</option>
                                                <option value="North Dakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="Puerto Rico">Puerto Rico</option>
                                                <option value="Rhode Island">Rhode Island</option>
                                                <option value="South Carolina">South Carolina</option>
                                                <option value="South Dakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virgin Islands">Virgin Islands</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="West Virginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Zip Code
                                                    <span class="required_filed">*</span>
                                                </strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <input autocomplete="nope" type="text" v-model="crm.zipCode" :class="{ 'error': submitted && $v.crm.zipCode.$error }" name="Zip_Code" class="form_field" id="Zip_Code" placeholder="Enter zip code here">
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form_box">
                                <div class="form_box_col1">
                                    <div class="group">
                                        <div class="form_label">
                                            <label class="text_uppercase">
                                                <strong>Message</strong>
                                            </label>
                                        </div>
                                        <div class="field_holder animated_box">
                                            <textarea
                                                name="Message"
                                                class="text form_field"
                                                id="mce-MESSAGE"
                                                v-model="crm.request"
                                                :class="{ error: submitted && $v.crm.request.$error }">
                                            </textarea>
                                            <span class="animated_class"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form_box5 secode_box text-center">
                                <div class="group">
                                    <div class="inner_form_box1 recapBtn">
                                        <div class="btn-submit">
                                            <button type="submit" class="button form_button" :disabled="loading">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { postNewOrder } from '@/api/apiCalls'
import alert from '@/components/layouts/alert'
import { mapGetters, mapMutations } from 'vuex'
import { getUserAgent, getUserIp } from '@/helpers'
import { required, email } from 'vuelidate/lib/validators'

export default {
    props: {
        total: {
            type: Number,
            required: true,
            default: 0
        },
    },
    data() {
        return {
            submitted: false,
            loading: false,
            crm: {
                state: ''
            },
            alertData: {
                showAlert: false,
                alertType: 'success',
                alertText: 'Data Added Successfully!',
            }
        }
    },
    validations: {
        crm: {
            clientName: { required },
            address: { required },
            city: { required },
            state: { required },
            zipCode: { required },
            phoneNumber: { required },
            email: { required, email },
            request: { },
        }
    },
    computed: {
        ...mapGetters('cart', ['items']),
    },
    components: {
        alert
    },
    methods: {
        ...mapMutations({
            setCartItems: `cart/SET_ITEMS`,
        }),
        handleSubmitForm() {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            // Scroll To Error
            this.$nextTick(() => {
                let element = document.querySelector('.form_field.error');
                if(element) {
                    let domRect = element.getBoundingClientRect();
                    window.scrollTo({
                        left: domRect.left + document.documentElement.scrollLeft,
                        top: domRect.top + (document.documentElement.scrollTop - 100),
                        behavior: 'smooth'
                    });
                }
            });

            if (!this.$v.$invalid) {
                this.loading = true;

                postNewOrder({
                    ...this.crm,
					ip: getUserIp(),
                    userAgent: getUserAgent(),
                    total: this.total,
                    status: 'Pending',
                    products: this.items.map(item  => {
                        if (item.hasOptions) {
                            const product = item.options.find(product => product.selected)

                            return {
                                itemId: item.id,
                                price: Number(product.price),
                                qty: product.qty,
                                size: product.size
                            }
                        } else {
                            return {
                                itemId: item.id,
                                price: Number(item.price),
                                qty: item.qty,
                                size: null,
                            }
                        }
                    }), // Submit Selected Products To Backend So we can generate Payment token with total price
                }).then(({ data }) => {

                    // Reset Store Cart
                    this.setCartItems([])

                    this.alertData.showAlert = true;
                    this.loading = false;
                    this.submitted = false;

                    this.$router.push({
                        name: 'Payment',
                        params: {
                            hash: data.data.hash
                        },
                        query: {
                            type: 'store'
                        }
                    });

                    this.crm = {}

                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                }).catch(() => {
                    this.alertData = {
                        showAlert: true,
                        alertType: "danger",
                        alertText: "Something Went Wrong!"
                    };
                    this.loading = false;
                    this.submitted = false;
                    setTimeout(() => {
                        this.alertData.showAlert = false;
                    }, 3000);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../../assets/css/forms.css";
</style>
